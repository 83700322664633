@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
.app{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    font-family: 'Roboto Mono', monospace;
}

.input-el{
    outline: none;
    border: 5px solid rgb(73, 71, 71);
    padding: 10px;
    margin: 10px;
    width: 50vw;
    text-align: center;
    background-color: #f5f5f5;
    transition: 500ms ease;
    font-size: large;
    
}

.input-el:hover, .input-el:focus{
    border: 5px solid #000;
}
.btn{
    padding: 15px;
    width: 10vw;
    background-color: #000;
    border: 1px solid #fff;
    color: #fff;
    transition: 500ms ease;
    font-size: large;
    margin: 10px;
}

.btn:hover{
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
}
.container{
    margin: 10px;
}


/* 
.grid-container{
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: auto auto auto;
    padding: 10px;
}

.grid-item{
    height: 35vh;
    background-color: cornflowerblue;
    margin: 40px;
}

@media screen and (max-width:900px) {
    .grid-container{
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width:450px){
    .grid-container{
        grid-template-columns: auto;
    }
}

@media screen and (max-width:550px) {
    .grid-item{
        margin: 10px;
    }
} */
.item{
    width: 40vw;
    height: 20vh;
    background-color: rgb(228, 194, 59);
  
}

.image{
    display: flex;
    justify-items: center;
}
body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100vh;
}
