.container{
    margin: 10px;
}


/* 
.grid-container{
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: auto auto auto;
    padding: 10px;
}

.grid-item{
    height: 35vh;
    background-color: cornflowerblue;
    margin: 40px;
}

@media screen and (max-width:900px) {
    .grid-container{
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width:450px){
    .grid-container{
        grid-template-columns: auto;
    }
}

@media screen and (max-width:550px) {
    .grid-item{
        margin: 10px;
    }
} */