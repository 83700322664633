@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

.app{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    font-family: 'Roboto Mono', monospace;
}

.input-el{
    outline: none;
    border: 5px solid rgb(73, 71, 71);
    padding: 10px;
    margin: 10px;
    width: 50vw;
    text-align: center;
    background-color: #f5f5f5;
    transition: 500ms ease;
    font-size: large;
    
}

.input-el:hover, .input-el:focus{
    border: 5px solid #000;
}
.btn{
    padding: 15px;
    width: 10vw;
    background-color: #000;
    border: 1px solid #fff;
    color: #fff;
    transition: 500ms ease;
    font-size: large;
    margin: 10px;
}

.btn:hover{
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
}